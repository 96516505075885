<template>
  <div class="d-flex align-center">
    <p id="logoText">
      IG-Scheduler
    </p>
  </div>
</template>

<script>
export default {
  name: "Logo"
}
</script>

<style scoped>
#logoText {
  margin-bottom: 0;
  padding-top: .3125rem;
  padding-bottom: .3125rem;
  margin-right: 1rem;
  font-size: 1.25rem;
  line-height: inherit;
  white-space: nowrap;
}

</style>
